import '../../core/grid/gridRow/styles.css';
import React from 'react';
import GridRow from '../../core/grid/gridRow';
import { ContentArray } from '../../core/grid/gridRow/index.types';
import { AgentTableContentProps } from './index.types';
import { IconButton, Tooltip } from '@mui/material';
import LocalActivityIcon from '@mui/icons-material/LocalActivity';
import { useNavigate } from 'react-router-dom';
import EditAgentModal from '../agentModal/EditAgentModal';
import DeleteAgentButton from '../DeleteAgentButton';
import moment from 'moment';

const AgentTableContent: React.FC<AgentTableContentProps> = ({
  agent,
  index,
  testId,
  handleSetTrigger,
}) => {
  const navigate = useNavigate();

  const contentList: ContentArray[] = [
    {
      id: 'agentIdColumn',
      value: [
        {
          id: 'agentId',
          class: 'agent-small',
          dataTestId: `${testId}Table-agentId`,
          value: agent.agentId,
        },
      ],
    },
    {
      id: 'ownerNameColumn',
      value: [
        {
          id: 'ownerName',
          class: 'agent-small',
          dataTestId: `${testId}Table-agentName`,
          value: agent.name,
        },
        {
          id: 'ownerPhoneNumber',
          class: 'agent-small',
          dataTestId: `${testId}Table-agentPhoneNumber`,
          value: agent.phoneNumber,
        },
      ],
    },
    {
      id: 'joinedAt',
      value: [
        {
          id: 'joinedAt',
          class: '',
          dataTestId: `${testId}Table-joinedAt`,
          value: moment(agent.createdAt).format('DD MMM YYYY, HH:mm'),
        },
      ],
    },
    {
      id: 'allocatedTicketsColumn',
      value: [
        {
          id: 'allocated',
          class: 'align-numbers',
          dataTestId: `${testId}Table-allocatedTickets`,
          value: agent.allocatedTickets,
        },
      ],
    },
    {
      id: 'remainingTicketsColumn',
      value: [
        {
          id: 'remaining',
          class: 'align-numbers',
          dataTestId: `${testId}Table-remainingTickets`,
          value: agent.remainingTickets,
        },
      ],
    },
    {
      id: 'actionsColumn',
      value: [
        {
          id: 'actions',
          class: 'actions',
          dataTestId: `${testId}Table-ticketsActionButtonsContainer`,
          value: (
            <div>
              <Tooltip title="Tickets">
                <IconButton
                  className="ticketButton"
                  aria-label="edit"
                  onClick={() => {
                    navigate(
                      `/tickets?agentId=${agent.agentId}&agentName=${agent.name}`,
                    );
                  }}
                  data-testid={`${testId}Table-agentTicketButton-${index}`}
                >
                  <LocalActivityIcon
                    style={{ transform: 'rotate(135deg)' }}
                    color="primary"
                    sx={{
                      '&:hover': {
                        cursor: 'pointer',
                      },
                    }}
                    data-testid={`${testId}Table-ticketsButton-${index}`}
                  />
                </IconButton>
              </Tooltip>
              <EditAgentModal
                agentData={agent}
                handleSetTrigger={handleSetTrigger}
                testId={testId}
                index={index}
              />
              <DeleteAgentButton
                handleSetTrigger={handleSetTrigger}
                agent={agent}
                testId={testId}
                index={index}
              />
            </div>
          ),
        },
      ],
    },
  ];

  return (
    <GridRow
      contentList={contentList}
      isActive={agent.isActive}
      index={index}
      testId={testId}
    />
  );
};

export default AgentTableContent;
